.room-container {
  background-color: black;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.room-iframe-wrapper {
  max-width: calc(100vh / 9 * 16);
  flex: 1;
  .room-iframe-container {
    position: relative;
    overflow: hidden;
    pointer-events: none;
    width: 100%;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

    .room-iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;

      .ytp-show-cards-title {
        display: none;
      }
    }
  }
}
.room-actions {
  position: fixed;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  opacity: 0.2;
  &:hover {
    opacity: 0.8;
  }
  > button {
    width: 36px;
    margin-bottom: 8px;
  }
}