.remote-playing-header {
  border-bottom: 1px solid darkgray;
  padding: 10px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.remote-playing-table {
  tr > th, td{
    &:first-of-type {
      width: 50px;
    }
    &:last-of-type {
      width: 100px;
    }
  }
}