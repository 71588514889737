.remote-search-header {
  border-bottom: 1px solid darkgray;
  padding: 10px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.remote-search-box {
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
  > button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}