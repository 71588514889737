.remote-song {
  margin-bottom: 10px;
}

.remote-song-header {
  word-wrap: break-word;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  margin-bottom: 3px;
}

.remote-song-body {
  display: flex;
  .remote-song-image {
    width: calc(100% - 70px);
    > img {
      width: 100%;
      height: auto;
    }
  }
  .remote-song-actions {
    width: 70px;
    padding: 5px;
    > button {
      margin-bottom: 5px;
      width: 100%;
    }
    .remote-song-already-added {
      font-size: 40px;
      text-align: center;
    }
  }
}
