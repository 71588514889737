.home-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;

  display: flex;
  flex-direction: column;

  padding: 20px;

  >.home-created-icon {
    font-size: 40px;
    margin-bottom: 10px;
  }

  > img {
    width: 70px;
    margin: 20px;
  }

  > a, button, input {
    width: 240px;
  }
}